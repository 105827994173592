import axios from "axios";
import jwt_decode from "jwt-decode";

const companionAPI = require("../api").companionAPI;

let API_URL = companionAPI;

class AuthService {
  register(data) {
    let queryUrl = API_URL + "/auth/register";

    const config = {
      method: "post",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    return axios(config);
  }

  hospitalLogin(hospitalId, pin) {
    return axios
      .post(API_URL + "/hospital/auth/login", {
        hospitalId,
        pin,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
        }
        return response.data;
      });
  }

  adminLogin(username, password) {
    return axios
      .post(API_URL + "/admin/auth/login", {
        username,
        password: password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
        }
        return response.data;
      });
  }

  forgot(email) {
    return axios
      .post(API_URL + "/auth/forgot", {
        email,
      })
      .then((response) => {
        return response.data;
      });
  }
  changePassword(data) {
    let queryUrl = API_URL + "/auth/change_password";

    const config = {
      method: "post",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    return axios(config);
  }

  logout() {
    localStorage.removeItem("token");
    window.location.replace("/");
  }

  getCurrentUser() {
    return jwt_decode(localStorage.getItem("token"));
  }
}

export default new AuthService();
