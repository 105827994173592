import axios from "axios";
const API_URL = require("../api").companionAPI;

class UserService {
  filterUsers(params) {
    let queryUrl = API_URL + "/users/get";

    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
      },
      params: params,
    };
    return axios(config);
  }
  updateUser(params) {
    let queryUrl = API_URL + "/users/update?userId=" + params.userId;

    const config = {
      method: "put",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    };
    return axios(config);
  }

  deleteUser(params) {
    let queryUrl = API_URL + "/users/delete?userId=" + params.userId;

    const config = {
      method: "delete",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  }
}

export default new UserService();
