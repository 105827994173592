import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import logo from "../assets/img/logo.svg";

const ForgotPassword = () => {
  const initialInputState = {
    email: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();

  const handleInputChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("email", eachEntry.email);
    AuthService.forgot(eachEntry.email).then(
      (response) => {
        history.go("/login");
        setIsLoading(false);
        setErrorMessage();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(resMessage || "Can't find user with provided credentials");
        setIsLoading(false);
      }
    );
  };

  return (
    <section className="login-block">
      <Helmet>
        <title>Companion - Forgot</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <form className="md-float-material form-material">
              <div className="auth-box card">
                <div className="card-block">
                  <div className="row m-b-20">
                    <div className="col-md-12">
                      <div className="text-center">
                        <img
                          src="../assets/img/logo.svg"
                          alt=""
                          className="logo-img"
                          width={147}
                          height={33}
                        />
                      </div>
                      <h3 className="text-center txt-primary">Forgot Password</h3>
                    </div>
                  </div>
                  <p className="text-muted text-center p-b-5">Admin Web Portal</p>
                  <div className="form-group form-primary">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={handleInputChange}
                      required
                    />
                    <span className="form-bar" />
                  </div>
                  <div className="row m-t-25 text-left">
                    <div className="col-12">
                      {errorMessage && (
                        <div className="row">
                          <div className="col">
                            <div className="alert alert-danger">
                              <p>
                                <strong>Forgot Password Failed!</strong> {errorMessage}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {errorMessage === null && (
                        <div className="row">
                          <div className="col">
                            <div className="alert alert-success">
                              <p>
                                <strong>Success!</strong> Check your email inbox to
                                recover your account
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="forgot-phone text-right float-right">
                        <Link to="/login" className="text-right f-w-600">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row m-t-30">
                    <div className="col-md-12">
                      {isLoading ? (
                        <button
                          type="submit"
                          onClick={handleForgotPassword}
                          className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                        >
                          <i class="fa fa-circle-o-notch fa-spin"></i>Loading
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={handleForgotPassword}
                          className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                        >
                          Forgot Password
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
