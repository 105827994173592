import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import authHeader from "../services/auth-header";

const Login = () => {
  const initialInputState = {
    hospitalId: "",
    pin: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();

  useEffect(() => {
    document.body.classList.add("public-layout");
    if (authHeader()) history.push("/");
  }, [history]);

  const handleInputChange = (e) => {
    const isBackspace = !e.target.validity.valid && eachEntry.pin.length === 1;
    if (!isBackspace && e.target.name === "pin") {
      setEachEntry({
        ...eachEntry,
        [e.target.name]: e.target.validity.valid ? e.target.value : eachEntry.pin,
      });
    } else setEachEntry({ ...eachEntry, [e.target.name]: e.target.value.toUpperCase() });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    AuthService.hospitalLogin(eachEntry.hospitalId, eachEntry.pin).then(
      (response) => {
        history.go("/");
        setIsLoading(false);
        setErrorMessage(null);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(resMessage);
        setIsLoading(false);
      }
    );
  };

  return (
    <div className="page-box">
      <Helmet>
        <title>Companion - Hospital</title>
      </Helmet>
      <div className="app-container page-sign-in" style={{ paddingLeft: "0px" }}>
        <div className="content-box">
          <div className="content-header">
            <div className="app-logo">
              <div className="logo-wrap">
                <img
                  src="../assets/img/logo.svg"
                  alt=""
                  className="logo-img"
                  width={147}
                  height={33}
                />
              </div>
            </div>
          </div>
          <div className="content-body">
            <div className="w-100">
              <h2 className="h4 mt-0 mb-1">Hospital Access Panel</h2>
              <p className="text-muted">Enter hospital login credentials</p>
              <form>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="hospitalId"
                    type="text"
                    value={eachEntry.hospitalId}
                    placeholder="ID"
                    maxLength="6"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="password"
                    name="pin"
                    pattern="[0-9]*"
                    maxLength="4"
                    placeholder="PIN"
                    value={eachEntry.pin}
                    required
                    onChange={handleInputChange}
                  />
                </div>

                {errorMessage && (
                  <div className="alert alert-danger with-before-icon" role="alert">
                    <div className="alert-icon">
                      <i className="icofont-info-circle" />
                    </div>
                    <div className="alert-content">
                      <p>Oops! {errorMessage}</p>
                    </div>
                  </div>
                )}

                <div className="actions justify-content-between">
                  {isLoading ? (
                    <button type="button" className="btn btn-primary btn-load">
                      Primary
                      <span className="btn-loader icofont-spinner" />
                      Loading
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={handleLogin}
                      type="submit"
                    >
                      <span className="btn-icon icofont-login mr-2" />
                      Sign in
                    </button>
                  )}
                </div>
              </form>
              <p className="mt-5 mb-1">
                <Link to="/forgot-pin">Forgot PIN ?</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
