import axios from "axios";
const API_URL = require("../api").companionAPI;

class StatisticsService {
  viewStatistics(params) {
    let queryUrl = API_URL + "/statistic/view";

    const config = {
      method: "post",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
      },
      data: params,
    };
    return axios(config);
  }

  getAdminStatistics(params) {
    let queryUrl = API_URL + "/statistic/hospital/get";

    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
      },
      params,
    };
    return axios(config);
  }

  getHospitalStatistics(params) {
    let queryUrl = API_URL + "/statistic/patients/get";
    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        token: localStorage.getItem("token"),
      },
      params,
    };
    return axios(config);
  }
}

export default new StatisticsService();
