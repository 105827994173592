import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  LoadingIndicator,
  MessageInput,
  MessageList,
  MessageTeam,
  Thread,
  Window,
  InfiniteScrollPaginator,
} from "stream-chat-react";
import { Helmet } from "react-helmet";

import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import "stream-chat-react/dist/css/index.css";
const apiKey = process.env.REACT_APP_STREAM_KEY;

const sort = { last_message_at: -1 };
const Paginator = (props) => <InfiniteScrollPaginator threshold={300} {...props} />;

const ChatRoomPage = () => {
  const [chatClient, setChatClient] = useState(null);

  const userToken = localStorage.getItem("token").replace(/['"]+/g, "");

  const filters = {
    type: "messaging",
    members: { $in: [AuthService.getCurrentUser().user_id] },
  };

  useEffect(() => {
    const initChat = async () => {
      const client = StreamChat.getInstance(apiKey, {
        timeout: 6000,
      });

      const displayName =
        AuthService.getCurrentUser().hospitalName === undefined
          ? `${AuthService.getCurrentUser().firstName} ${
              AuthService.getCurrentUser().lastName
            }`
          : AuthService.getCurrentUser().hospitalName;

      //   await client.disconnectUser();
      await client.connectUser(
        {
          id: AuthService.getCurrentUser().user_id,
          name: displayName,
          image: "https://icotar.com/avatar/:" + AuthService.getCurrentUser().firstName,
        },
        userToken
      );

      setChatClient(client);
    };

    initChat();
  }, []);

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Chat</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        <div className="page-content">
          {/* <div className="row"> */}
          <div className="col-md-12 ">
            <Chat client={chatClient} theme="messaging">
              <ChannelList filters={filters} sort={sort} Paginator={Paginator} />
              <Channel>
                <Window>
                  <ChannelHeader />
                  <MessageList Message={MessageTeam} />
                  <MessageInput />
                </Window>
                <Thread />
              </Channel>
            </Chat>
          </div>
          {/* </div> */}
        </div>
      </div>
    </main>
  );
};

export default ChatRoomPage;
