import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import UserService from "../services/user.service";

import AuthService from "../services/auth.service";
import logoWhite from "../assets/img/logo-white.svg";
const screenfull = require("screenfull");

function TopNavigation() {
  const [collapse, setCollapse] = React.useState(false);

  const currentUser = AuthService.getCurrentUser();
  // const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    // UserService.filterUsers({
    // id: AuthService.getCurrentUser().id,
    // }).then((user) => {
    // setCurrentUser(user.data.data.users[0]);
    // });
  }, []);

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  return (
    <div id="navbar1" className="app-navbar horizontal">
      <div className="navbar-wrap">
        <button className="no-style navbar-toggle navbar-open d-lg-none">
          <span />
          <span />
          <span />
        </button>

        <div className="app-actions">
          <div className="dropdown item">
            <button
              className="no-style dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-offset="0, 10"
            >
              <span className="d-flex align-items-center">
                <img
                  src={`https://icotar.com/avatar/:${
                    AuthService.getCurrentUser().USER_ID
                  }`}
                  alt=""
                  width={40}
                  height={40}
                  className="rounded-500 mr-1"
                />
                <i className="icofont-simple-down" />
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-w-180">
              <ul className="list">
                {/*<li>*/}
                {/*  <a href="#" className="align-items-center">*/}
                {/*    <span className="icon icofont-ui-user" />*/}
                {/*    User profile*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li>
                  <a onClick={AuthService.logout} className="align-items-center">
                    <span className="icon icofont-logout" />
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="navbar-skeleton horizontal">
          <div className="left-part d-flex align-items-center">
            <span className="navbar-button bg animated-bg d-lg-none" />
            <span className="sk-logo bg animated-bg d-none d-lg-block" />
          </div>
          <div className="right-part d-flex align-items-center">
            <div className="icon-box">
              <span className="icon bg animated-bg" />
              <span className="badge" />
            </div>
            <span className="avatar bg animated-bg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNavigation;
