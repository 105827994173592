import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AuthService from "../services/auth.service";
import Pagination from "@material-ui/lab/Pagination";
import {NavLink, useHistory} from "react-router-dom";
import ExportDATA from "../components/Util/ExportDATA";
import Swal from "sweetalert2";
import moment from "moment";
import recordService from "../services/record.service";

const badgeCreator = (isActive) => {
  switch (isActive) {
    case 1:
      return <span className="badge badge-success">Active</span>;
    default:
      return <span className="badge badge-light">Disabled</span>;
  }
};

const RecordsPage = () => {
  const initialInputState = {
    "userId": "",
    "hospitalId": "",
    "recordType": "",
    "weight": "",
    "bloodPressure": "",
    "CD4count": "",
    "vlNumber": ""
  };

  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [recordsLoaded, setRecordsLoaded] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [rawData, setRawData] = useState([]);

  const [modal, setModal] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 50];

  // Handle search input
  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  // Parse Parameters
  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};
    params["hospitalId"] = AuthService.getCurrentUser().user_id;

    if (searchTitle) {
      params["phoneNumber"] = searchTitle;
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  // Load Records
  const loadRecords = () => {
    const params = getRequestParams(searchTitle, page, pageSize);

    recordService.filterRecords(params).then((res) => {
      setRecords(res.data.data.records);
      setCount(res.data.data.totalPages);
      setRecordsLoaded(true);
      console.log(res.data)
      const newData = res.data.data.records.map((d) => ({
        id:d.id,
        userId: d.user.id,
        fullNames: `${d.user.firstName.toUpperCase()} ${d.user.lastName}`,
        gender: d.user.gender.toUpperCase(),
        age: moment().diff(moment(d.user.dob), "years"),
        phoneNumber: "+" + d.user.phoneNumber,
        reportedAt: moment(d.createdAt).format("DD MMM YYYY"),
        CD4count:d.CD4count ? `${d.CD4count} avg` : '-',
        bloodPressure: `${d.bloodPressure} mmHg`,
        recordTypeId:d.recordTypeId,
        vlNumber:d.vlNumber?`${d.vlNumber} avg`:'-',
        weight:`${d.weight} Kg`
      }));
      setRawData(newData);
    });
  };

  // Effectively loading patients
  useEffect(() => {
    loadRecords();
  }, [page, pageSize]);

  // Search by Title
  const findByTitle = () => {
    setPage(1);
    loadRecords();
  };

  // Handle page number change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // Input handling
  const handleInputChange = (e) => {
    const isBackspace = !e.target.validity.valid && eachEntry.phoneNumber.length === 1;
    if (!isBackspace && e.target.name === "phoneNumber") {
      setEachEntry({
        ...eachEntry,
        [e.target.name]: e.target.validity.valid ? e.target.value : eachEntry.phoneNumber,
      });
    } else setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  // Create Record
  const handleCreateRecord = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // patientService
    //   .createPatients({
    //     phoneNumber: "250" + eachEntry.phoneNumber,
    //     firstName: eachEntry.firstName,
    //     lastName: eachEntry.lastName,
    //     dob: eachEntry.dob,
    //     gender: eachEntry.gender,
    //     address: eachEntry.address,
    //     hospitalId: AuthService.getCurrentUser().user_id,
    //     regimenId: eachEntry.regimenId,
    //   })
    //   .then((result) => {
    //     setIsLoading(false);
    //     setModal(false);
    //     loadPatients();
    //     setEachEntry(initialInputState);
    //     if (result.data.status) {
    //       Swal.fire("Success !", result.data.message, "success");
    //     } else {
    //       Swal.fire("Error", result.data.message, "error");
    //     }
    //   });
  };


  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Records</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        <header className="page-header">
          <h1 className="page-title">Records</h1>
        </header>
        <div className="page-content">
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <ExportDATA
                  data={rawData}
                  fileName={`Patients_${moment().format("YYYYMMDD")}`}
                />
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Phone Number Ex: 250XXXXXXXXX"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={findByTitle}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th scope="col">Names</th>
                      <th scope="col">Age</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Blood Pressure</th>
                      <th scope="col">Weight</th>
                      <th scope="col">CD4 Count</th>
                      <th scope="col">VL Number</th>
                      <th scope="col">Recorded At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rawData.map((record, index) => (
                      <tr key={record.id}>
                        <td>
                          <NavLink to={`/patient/${record.userId}`}>
                            <strong>{record.fullNames}</strong>
                          </NavLink>
                        </td>
                        <td>{record.age}</td>
                        <td>
                          {record.gender}
                        </td>
                        <td>
                          <div className="d-flex align-items-center nowrap text-primary">
                            <span className="icofont-ui-cell-phone p-0 mr-2" />
                            {record.phoneNumber}
                          </div>
                        </td>
                        <td>
                          {record.bloodPressure}
                        </td>
                        <td>
                          {record.weight}
                        </td>
                        <td>
                          {record.CD4count}
                        </td>
                        <td>
                          {record.vlNumber}
                        </td>
                        <td>
                          {record.reportedAt}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className=" mt-4">
                {"Patients per Page: "}

                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  color="primary"
                  variant="outlined"
                  shape="round"
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>
          <div className="add-action-box">
            <button
              className="btn btn-primary btn-lg btn-square rounded-pill"
              onClick={() => setModal(false)}
            >
              <span className="btn-icon icofont-plus" />
            </button>
          </div>
        </div>
      </div>

      {/* Add record modals */}
      <Modal isOpen={modal} toggle={() => setModal(!modal)} fade>
        <ModalHeader toggle={() => setModal(!modal)}>Add new record</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={eachEntry.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="lastName"
                value={eachEntry.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="date"
                required
                name="dob"
                value={eachEntry.dob}
                onChange={handleInputChange}
                placeholder="Date of birth"
              />
            </div>
            <div className="form-group">
              <select
                name="gender"
                className="form-control form-control-default"
                onChange={handleInputChange}
              >
                <option value={null}>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="address"
                value={eachEntry.address}
                onChange={handleInputChange}
                placeholder="Address"
              />
            </div>
            <div className="form-group">
              <select
                name="regimenId"
                className="form-control form-control-default"
                onChange={handleInputChange}
              >
                <option value={null}>Select Regimen</option>
                {/*{regimens.map((regimen) => (*/}
                {/*  <option value={regimen.id}>{regimen.name}</option>*/}
                {/*))}*/}
              </select>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">+250</span>
              </div>
              <input
                className="form-control"
                type="text"
                pattern="[0-9]*"
                maxLength="9"
                required
                value={eachEntry.phoneNumber}
                name="phoneNumber"
                onChange={handleInputChange}
                placeholder="Phone"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            <button
              type="button"
              className="btn btn-error"
              onClick={() => setModal(!modal)}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                type="submit"
                onClick={handleCreateRecord}
                className="btn btn-primary"
              >
                <i className="fa fa-circle-o-notch fa-spin"></i>&nbsp;Creating record
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleCreateRecord}
                className="btn btn-primary"
              >
                Add record
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add record modals */}
    </main>
  );
};

export default RecordsPage;
