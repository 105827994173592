import axios from "axios";
const API_URL = require("../api").companionAPI;

class ReportService {

  filterReports(params) {
    let queryUrl = API_URL + "/report/get";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");
    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
      },
      params,
    };
    return axios(config);
  }

}

export default new ReportService();
