import axios from "axios";
const API_URL = require("../api").companionAPI;

class RegimenService {
  filterRegimens(params) {
    let queryUrl = API_URL + "/regimens/get";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");
    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
      },
      params,
    };
    return axios(config);
  }

  createRegimens(params) {
    let queryUrl = API_URL + "/regimens/create";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");

    const config = {
      method: "post",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    };
    return axios(config);
  }
}

export default new RegimenService();
