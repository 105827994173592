import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Routes from "./routes";
import TopNavigation from "./components/topNavigation";
import SideNavigation from "./components/sideNavigation";
import Footer from "./components/Footer";

import { useLocation } from "react-router-dom";

import "./css/index.css";
import authHeader from "./services/auth-header";

const App = () => {
  let location = useLocation();
  const [role, setRole] = useState("hospital");

  useEffect(() => {
    if (location.pathname === "/admin") {
      return setRole("admin");
    } else {
      setRole("hospital");
    }
  }, []);

  const isAuth = authHeader();
  return isAuth ? (
    <div className="page-box">
      <div className="app-container">
        <TopNavigation />
        <SideNavigation />
        <Routes />
        <Footer />
        <div className="content-overlay"></div>
      </div>
    </div>
  ) : (
    <LoginSwitcher role={role} />
  );
};

export default App;

const LoginSwitcher = ({ role }) => {
  switch (role) {
    case "admin":
      return (
        <>
          <Routes />
          <Redirect to="/admin" />
        </>
      );
    default:
      return (
        <>
          <Routes />
          <Redirect to="/hospital" />
        </>
      );
  }
};
