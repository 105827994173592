import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CountUp from "react-countup";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import moment from "moment";
import statisticsService from "../services/statistics.service";
import AuthService from "../services/auth.service";
import { NavLink, useHistory } from "react-router-dom";
ChartJS.register(
  ArcElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const badgeCreator = (isActive) => {
  switch (isActive) {
    case 1:
      return <span className="badge badge-success">Active</span>;
    case 0:
      return <span className="badge badge-light">Disabled</span>;
  }
};

const StatisticsPage = () => {
  const [statistics, setStatistics] = useState({});
  const [statisticsLoaded, setStatisticsLoaded] = useState(false);
  const [dataSurvey, setDataSurvey] = useState({});
  const [role, setRole] = useState();

  const history = useHistory();

  useEffect(() => {
    const currentRole = AuthService.getCurrentUser()?.role;
    setRole(currentRole);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.go("/hospital");
    }

    if (role === "administrator") {
      statisticsService.viewStatistics().then((res) => {
        const { data } = res.data;
        setStatistics(data);
        setStatisticsLoaded(true);
      });
      statisticsService.getAdminStatistics().then((res) => {
        const { data } = res;
        setDataSurvey(data?.series);
      });
    }

    if (role === "hospital") {
      console.log(role);
      const params = { hospitalId: AuthService.getCurrentUser().user_id };
      statisticsService.viewStatistics(params).then((res) => {
        const { data } = res.data;
        setStatistics(data);
        setStatisticsLoaded(true);
      });
      statisticsService.getHospitalStatistics(params).then((res) => {
        const { data } = res;
        setDataSurvey(data?.series);
      });
    }
  }, []);

  const backgroundColor = [
    "#0042C6",
    "#F07D6F",
    "#F5B330",
    "#00D1EE",
    "#FFC49F",
    "#00A18C",
    "#2F73C2",
    "#5F63CC",
  ];

  const options = {
    borderWidth: 0,
    cutout: 140,
    radius: 200,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          usePointStyle: true,
          boxWidth: 18,
          color: "#00002C",
          padding: 16,
        },
      },
    },
  };

  const rounder = (num = 0) => {
    let multiplier = Math.pow(10, num.toString().length - 1);
    let module = num % multiplier;
    let diff = (3 * multiplier) / 10;
    if (module >= diff) {
      return Math.ceil(num / multiplier) * multiplier;
    }
    return Math.floor(num / multiplier) * multiplier;
  };

  const dataPlot = [].concat(dataSurvey)?.map((survey) => ({
    x: moment(survey.date).format("DD MMM"),
    y: role === "hospital" ? survey.patients : survey.hospitals,
  }));

  const yAxisValues = dataPlot?.map((count) => count.y);

  const max = rounder(yAxisValues.sort((a, b) => a - b)[yAxisValues.length - 1]);
  const stepSize = Math.ceil(max / 3);
  const lineOptions = {
    type: "line",
    legend: {
      display: false,
    },
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        min: 0,
        max,
        ticks: {
          stepSize,
        },
      },
      x: {
        offset: true,
        ticks: {
          padding: 8.5,
          align: "center",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: dataPlot?.map((label) => label.x),
    datasets: [
      {
        data: dataPlot,
        borderColor: "#64B5F6",
        borderWidth: 2,
        radius: 0,
      },
    ],
  };

  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Statistics</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        {statisticsLoaded && (
          <div className="page-content">
            {role === "hospital" && (
              <div className="row">
                <div className="col col-12 col-md-8 col-xl-6">
                  <div className="card animated fadeInUp delay-03s bg-light">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col col-5">
                          <div className="icon p-0 fs-48 text-primary opacity-50 icofont-patient-bed" />
                        </div>
                        <div className="col col-7">
                          <h6 className="mt-0 mb-1">Patients</h6>
                          <div className="count text-primary fs-20">
                            <CountUp end={statistics.totals.patients || 0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-md-8 col-xl-6">
                  <div className="card animated fadeInUp delay-02s bg-light">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col col-5">
                          <div className="icon p-0 fs-48 text-primary opacity-50 icofont-patient-file" />
                        </div>
                        <div className="col col-7">
                          <h6 className="mt-0 mb-1">Records</h6>
                          <div className="count text-primary fs-20">
                            <CountUp end={statistics.totals.records || 0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {role === "administrator" && (
              <div className="row">
                <div className="col col-12 col-md-6 col-xl-3">
                  <div className="card animated fadeInUp delay-01s bg-light">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col col-5">
                          <div className="icon p-0 fs-48 text-primary opacity-50 icofont-first-aid-alt" />
                        </div>
                        <div className="col col-7">
                          <h6 className="mt-0 mb-1">Hospitals</h6>
                          <div className="count text-primary fs-20">
                            <CountUp end={statistics.totals.hospitals || 0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-md-6 col-xl-3">
                  <div className="card animated fadeInUp delay-03s bg-light">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col col-5">
                          <div className="icon p-0 fs-48 text-primary opacity-50 icofont-patient-bed" />
                        </div>
                        <div className="col col-7">
                          <h6 className="mt-0 mb-1">Patients</h6>
                          <div className="count text-primary fs-20">
                            <CountUp end={statistics.totals.patients || 0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-md-6 col-xl-3">
                  <div className="card animated fadeInUp delay-02s bg-light">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col col-5">
                          <div className="icon p-0 fs-48 text-primary opacity-50 icofont-patient-file" />
                        </div>
                        <div className="col col-7">
                          <h6 className="mt-0 mb-1">Records</h6>
                          <div className="count text-primary fs-20">
                            <CountUp end={statistics.totals.records || 0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-md-6 col-xl-3">
                  <div className="card animated fadeInUp delay-04s bg-light">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col col-5">
                          <div className="icon p-0 fs-48 text-primary opacity-50 icofont-pills" />
                        </div>
                        <div className="col col-7">
                          <h6 className="mt-0 mb-1 text-nowrap">Reports</h6>
                          <div className="count text-primary fs-20">
                            <CountUp end={statistics.totals.reports || 0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {role === "administrator" && (
              <div className="card">
                <div className="card-header">Hospital survey</div>
                <div className="card-body">
                  <Line
                    className="chat-container container-h-400"
                    options={lineOptions}
                    data={data}
                  />
                </div>
              </div>
            )}
            {role === "hospital" && (
              <div className="card">
                <div className="card-header">Patient survey</div>
                <div className="card-body">
                  <Line
                    className="chat-container container-h-400"
                    options={lineOptions}
                    data={data}
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col col-12 col-md-6">
                {role === "hospital" && (
                  <div className="card">
                    <div className="card-header">Patients age</div>
                    <div className="card-body">
                      <Pie
                        data={{
                          labels: Object.keys(statistics?.age),
                          datasets: [
                            {
                              data: Object.values(statistics?.age),
                              backgroundColor,
                            },
                          ],
                        }}
                        options={options}
                        type="pie"
                      />
                    </div>
                  </div>
                )}
                {role === "administrator" && (
                  <div className="card">
                    <div className="card-header">Hospitals</div>
                    <div className="card-body">
                      <Pie
                        data={{
                          labels: Object.keys(statistics?.hospitals),
                          datasets: [
                            {
                              data: Object.values(statistics?.hospitals),
                              backgroundColor,
                            },
                          ],
                        }}
                        options={options}
                        type="pie"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col col-12 col-md-6">
                <div className="card">
                  <div className="card-header">Patients gender</div>
                  <div className="card-body">
                    <Pie
                      data={{
                        labels: Object.keys(statistics?.gender).map(
                          (a) => a.charAt(0).toUpperCase() + a.substr(1)
                        ),
                        datasets: [
                          {
                            data: Object.values(statistics?.gender),
                            backgroundColor: backgroundColor.slice(0, 2),
                          },
                        ],
                      }}
                      options={options}
                      type="pie"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-0">
              <div className="card-header">
                {role === "hospital" ? "Latest Added Patients" : "Latest Added Hospitals"}
              </div>
              <div className="card-body">
                {role === "administrator" && (
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th scope="col">ID</th>
                          <th scope="col">Hospital Name</th>
                          <th scope="col">Contact Person</th>
                          <th scope="col">Contact Phone</th>
                          <th scope="col">Registered At</th>
                          <th scope="col">Activated / Deactivated</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistics?.latest?.hospitals?.map((hospital) => (
                          <tr key={hospital.hospitalId}>
                            <td>{hospital.hospitalId}</td>
                            <td>
                              <strong>{hospital.hospitalName}</strong>
                            </td>
                            <td>{hospital.hospitalContactPersonName}</td>
                            <td>
                              <div className="d-flex align-items-center nowrap text-primary">
                                <span className="icofont-ui-cell-phone p-0 mr-2" />
                                {hospital.hospitalContactPersonPhone}
                              </div>
                            </td>
                            <td>
                              {moment(hospital.createdAt).format("DD-MM-YYYY HH:MM")}
                            </td>
                            <td>{badgeCreator(hospital.isActive)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {role === "hospital" && (
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th scope="col">#</th>
                          <th scope="col">Patient name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Address</th>
                          <th scope="col">Activated / Deactivated</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistics?.latest?.patients?.map((patient, index) => (
                          <tr key={patient.id}>
                            <td>{index + 1}</td>
                            <td>
                              <NavLink to={`/patient/${patient.id}`}>
                                <strong>{`${patient.firstName} ${patient.lastName}`}</strong>
                              </NavLink>
                            </td>
                            <td>{moment().diff(moment(patient.dob), "years")}</td>
                            <td>{patient.gender.toUpperCase()}</td>
                            <td>
                              <div className="d-flex align-items-center nowrap text-primary">
                                <span className="icofont-ui-cell-phone p-0 mr-2" />
                                {`+${patient.phoneNumber}`}
                              </div>
                            </td>
                            <td>{patient.address}</td>
                            <td>{badgeCreator(patient.isActive)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default StatisticsPage;
