import axios from "axios";
const API_URL = require("../api").companionAPI;

class RecordService {
    filterRecords(params) {
        let queryUrl = API_URL + "/record/get";
        let token = localStorage.getItem("token").replace(/['"]+/g, "");
        const config = {
            method: "get",
            url: queryUrl,
            headers: {
                Authorization: "Bearer " + token,
            },
            params,
        };
        return axios(config);
    }

    createRecord(params) {
        let queryUrl = API_URL + "/record/create";
        let token = localStorage.getItem("token").replace(/['"]+/g, "");

        const config = {
            method: "post",
            url: queryUrl,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: JSON.stringify(params),
        };
        return axios(config);
    }

}

export default new RecordService();
