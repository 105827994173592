import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import LoginAdminPage from "./layout/adminLogin";
import LoginHospitalPage from "./layout/loginHospital";
import StatisticsPage from "./pages/StatisticsPage.js";
import ChatRoomPage from "./pages/ChatRoomPage";
import App from "./App";
import HospitalsPage from "./pages/HospitalsPage";
import RegimensPage from "./pages/RegimensPage";
import PatientsPage from "./pages/PatientsPage";
import PatientPage from "./pages/PatientPage";
import ReportsPage from "./pages/ReportsPage";
import RecordsPage from "./pages/RecordsPage";
import ProfilePage from "./pages/ProfilePage";
import ForgotPassword from "./layout/forgotPassword";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={StatisticsPage} />
      <Route path="/admin" component={LoginAdminPage} />
      <Route path="/hospital" component={LoginHospitalPage} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/hospitals" component={HospitalsPage} />
      <Route path="/regimens" component={RegimensPage} />
      <Route path="/patients" component={PatientsPage} />
      <Route path="/patient/:userId" component={PatientPage} />
      <Route path="/reports" component={ReportsPage} />
      <Route path="/records" component={RecordsPage} />
      <Route path="/chat" component={ChatRoomPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/404">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Routes;
