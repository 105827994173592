import jwt_decode from "jwt-decode";

const authHeader = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  } else {
    if (jwt_decode(token).exp < Date.now() / 1000) {
      localStorage.clear();
      return false;
    }
  }

  return true;
};

export default authHeader;
