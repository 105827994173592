import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import ExportDATA from "../components/Util/ExportDATA";
import Swal from "sweetalert2";
import moment from "moment";
import hospitalService from "../services/hospital.service";

const labelCreator = (status) => {
  switch (status) {
    case "pending":
      return `label label-warning`;
    case "success":
      return `label label-success`;
    case "failed":
      return `label label-danger`;
  }
};

const badgeCreator = (isActive) => {
  switch (isActive) {
    case 1:
      return <span className="badge badge-success">Active</span>;
    default:
      return <span className="badge badge-light">Disabled</span>;
  }
};

const HospitalsPage = () => {
  const initialInputState = {
    hospitalName: "",
    hospitalContactPersonName: "",
    hospitalContactPersonPhone: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [hospitalsLoaded, setHospitalsLoaded] = useState(false);
  const [currentHospital, setCurrentHospital] = useState({});
  const [rawData, setRawData] = useState([]);

  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 50];

  // Handle search input
  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  // Parse Parameters
  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["hospitalContactPersonPhone"] = searchTitle;
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  // Load Hospitals
  const loadHospitals = () => {
    const params = getRequestParams(searchTitle, page, pageSize);

    hospitalService.filterHospitals(params).then((res) => {
      setHospitals(res.data.data.hospitals);
      setCount(res.data.data.totalPages);
      setHospitalsLoaded(true);
      const newData = res.data.data.hospitals.map((d) => ({
        id: d.id,
        hospitalId: d.hospitalId,
        hospitalName: d.hospitalName,
        hospitalContactPersonName: d.hospitalContactPersonName,
        hospitalContactPersonPhone: "+" + d.hospitalContactPersonPhone,
        createdAt: moment(d.createdAt).format("DD MMM YYYY"),
        isActive: d.isActive,
      }));
      setRawData(newData);
    });
  };

  // Effectively loading hospitals
  useEffect(() => {
    loadHospitals();
  }, [page, pageSize]);

  // Search by Title
  const findByTitle = () => {
    setPage(1);
    loadHospitals();
  };

  // Handle page number change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // Input handling
  const handleInputChange = (e) => {
    const isBackspace =
      !e.target.validity.valid && eachEntry.hospitalContactPersonPhone.length === 1;
    if (!isBackspace && e.target.name === "hospitalContactPersonPhone") {
      setEachEntry({
        ...eachEntry,
        [e.target.name]: e.target.validity.valid
          ? e.target.value
          : eachEntry.hospitalContactPersonPhone,
      });
    } else setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  // Create Hospital
  const handleCreateHospital = (e) => {
    e.preventDefault();
    setIsLoading(true);
    hospitalService
      .createHospitals({
        hospitalName: eachEntry.hospitalName,
        hospitalContactPersonName: eachEntry.hospitalContactPersonName,
        hospitalContactPersonPhone: "250" + eachEntry.hospitalContactPersonPhone,
      })
      .then((result) => {
        setIsLoading(false);
        setModal(false);
        loadHospitals();
        if (result.data.status) {
          Swal.fire("Success !", result.data.message, "success");
        } else {
          Swal.fire("Error", result.data.message, "error");
        }
      });
  };
  // Update Hospital
  const handleUpdateHospital = (e) => {
    const params = {
      hospitalId: currentHospital.id,
      hospitalName: eachEntry.hospitalName,
      hospitalContactPersonName: eachEntry.hospitalContactPersonName,
    };

    e.preventDefault();
    setIsLoading(true);
    hospitalService.updateHospital(params).then((result) => {
      setIsLoading(false);
      setUpdateModal(!updateModal);
      if (result.data.success) {
        loadHospitals();
        Swal.fire("Success !", result.data.message, "success");
      } else {
        Swal.fire("Oops, Error !", result.data.message, "error");
      }
    });
  };

  // Toggle Update Modal
  const toggleUpdate = (hospitalId) => {
    hospitalService
      .filterHospitals({ id: hospitalId })
      .then((res) => {
        setCurrentHospital(res.data.data.hospitals[0]);
        setUpdateModal(!updateModal);
      })
      .catch((err) => console.error(err));
  };

  // Disable Hospital
  const changeHospitalStatus = (hospitalId) => {
    hospitalService
      .filterHospitals({ id: hospitalId })
      .then((res) => {
        const hospital = res.data.data.hospitals[0];
        setCurrentHospital(hospital);
        let status = hospital.isActive === 1 ? "deactivate" : "activate";
        Swal.fire({
          icon: "question",
          text: `Are you sure you want to ${status} ${hospital.hospitalName}`,
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.isConfirmed) {
            const params = {
              hospitalId: currentHospital.id,
              isActive: Number(!Boolean(currentHospital.isActive)),
            };
            hospitalService.updateHospital(params).then((result) => {
              setIsLoading(false);
              if (result.data.success) {
                loadHospitals();
                Swal.fire("Success !", `${hospital.hospitalName} ${status}d`, "success");
              } else {
                Swal.fire("Oops, Error !", result.data.message, "error");
              }
            });
          }
        });
      })
      .catch((err) => console.error(err));
  };

  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Hospitals</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        <header className="page-header">
          <h1 className="page-title">Hospitals</h1>
        </header>
        <div className="page-content">
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <ExportDATA
                  data={hospitals}
                  fileName={`Hospitals_${moment().format("YYYYMMDD")}`}
                />
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Contact Person Phone Ex: 250XXXXXXXXX"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={findByTitle}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th scope="col">ID</th>
                      <th scope="col">Hospital Name</th>
                      <th scope="col">Contact Person</th>
                      <th scope="col">Contact Phone</th>
                      <th scope="col">Registered At</th>
                      <th scope="col">Activated / Deactivated</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rawData.map((hospital) => (
                      <tr key={hospital.hospitalId}>
                        <td>{hospital.hospitalId}</td>
                        <td>
                          <strong>{hospital.hospitalName}</strong>
                        </td>
                        <td>{hospital.hospitalContactPersonName}</td>
                        <td>
                          <div className="d-flex align-items-center nowrap text-primary">
                            <span className="icofont-ui-cell-phone p-0 mr-2" />
                            {hospital.hospitalContactPersonPhone}
                          </div>
                        </td>
                        <td>{hospital.createdAt}</td>
                        <td>{badgeCreator(hospital.isActive)}</td>
                        <td>
                          <div className="actions">
                            <button
                              className="btn btn-info btn-sm btn-square rounded-pill"
                              onClick={() => toggleUpdate(hospital.id)}
                            >
                              <span className="btn-icon icofont-ui-edit" />
                            </button>
                            {hospital.isActive === 1 ? (
                              <button
                                className="btn btn-error btn-sm btn-square rounded-pill"
                                onClick={() => changeHospitalStatus(hospital.id)}
                              >
                                <span className="btn-icon icofont-not-allowed" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-success btn-sm btn-square rounded-pill"
                                onClick={() => changeHospitalStatus(hospital.id)}
                              >
                                <span className="btn-icon icofont-tick-mark" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className=" mt-4">
                {"Hospitals per Page: "}

                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  color="primary"
                  variant="outlined"
                  shape="round"
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>
          <div className="add-action-box">
            <button
              className="btn btn-primary btn-lg btn-square rounded-pill"
              onClick={() => setModal(!modal)}
            >
              <span className="btn-icon icofont-plus" />
            </button>
          </div>
        </div>
      </div>

      {/* Add hospital modals */}
      <Modal isOpen={modal} toggle={() => setModal(!modal)} fade>
        <ModalHeader toggle={() => setModal(!modal)}>Add new hospital</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="hospitalName"
                value={eachEntry.hospitalName}
                onChange={handleInputChange}
                placeholder="Hospital Name"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="hospitalContactPersonName"
                value={eachEntry.hospitalContactPersonName}
                onChange={handleInputChange}
                placeholder="Contact Person Name"
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">+250</span>
              </div>
              <input
                className="form-control"
                type="text"
                pattern="[0-9]*"
                maxLength="9"
                required
                value={eachEntry.hospitalContactPersonPhone}
                name="hospitalContactPersonPhone"
                onChange={handleInputChange}
                placeholder="Contact Person Phone"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            <button
              type="button"
              className="btn btn-error"
              onClick={() => setModal(!modal)}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                type="submit"
                onClick={handleCreateHospital}
                className="btn btn-info"
              >
                <i className="fa fa-circle-o-notch fa-spin"></i>&nbsp;Creating Hospital
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleCreateHospital}
                className="btn btn-info"
              >
                Add hospital
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add hospital modals */}
      {/* Update hospital modals */}
      <Modal isOpen={updateModal} toggle={() => toggleUpdate(null)} fade>
        <ModalHeader toggle={() => toggleUpdate(null)}>Update hospital</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="hospitalName"
                value={eachEntry.hospitalName}
                onChange={handleInputChange}
                placeholder={currentHospital.hospitalName}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="hospitalContactPersonName"
                value={eachEntry.hospitalContactPersonName}
                onChange={handleInputChange}
                placeholder={currentHospital.hospitalContactPersonName}
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">+250</span>
              </div>
              <input
                className="form-control"
                type="text"
                pattern="[0-9]*"
                maxLength="9"
                required
                value={currentHospital.hospitalContactPersonPhone}
                name="hospitalContactPersonPhone"
                onChange={handleInputChange}
                placeholder={currentHospital.hospitalContactPersonPhone}
                disabled
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            {isLoading ? (
              <button
                type="button"
                onClick={handleUpdateHospital}
                className="btn btn-info"
              >
                <i className="fa fa-circle-o-notch fa-spin"></i>&nbsp;Updating Hospital
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleUpdateHospital}
                className="btn btn-info"
              >
                Update hospital
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add hospital modals */}
    </main>
  );
};

export default HospitalsPage;
