import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { useHistory } from "react-router-dom";

function ProfilePage() {
  const initialInputState = {
    firstName: "",
    lastName: "",
    password: "",
    newPassword: "",
  };

  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  const history = useHistory();

  useEffect(() => {
    UserService.filterUsers({
      id: AuthService.getCurrentUser().id,
    }).then((user) => {
      setCurrentUser(user.data.data.users[0]);
    });
  }, []);

  const handleUpdateUser = (e) => {
    const params = {
      userId: AuthService.getCurrentUser().id,
      firstName: eachEntry.firstName,
      lastName: eachEntry.lastName,
    };
    e.preventDefault();
    setIsLoading(true);
    UserService.updateUser(params).then((user) => {
      setIsLoading(false);
      console.log(`user`, user);
      history.go("/profile");
    });
  };

  const handleChangePassword = (e) => {
    if (eachEntry.newPassword !== eachEntry.confirmPassword) {
      setErrorMessage("New password should match confirmed password");
      return;
    }
    setErrorMessage(null);
    const data = {
      username: AuthService.getCurrentUser().username,
      password: eachEntry.password,
      newPassword: eachEntry.newPassword,
    };
    e.preventDefault();
    setIsLoading2(true);
    AuthService.changePassword(data).then(
      (user) => {
        setIsLoading2(false);
        console.log(`user`, user);
        AuthService.logout();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(resMessage);
        setIsLoading(false);
      }
    );
    return;
  };

  const handleInputChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  return (
    <div className="pcoded-content">
      <Helmet>
        <title>AirExchange - Profile</title>
      </Helmet>
      <div className="page-header card">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <div className="page-header-title">
              <i className="feather icon-user bg-c-blue" />
              <div className="d-inline">
                <h5>Profile</h5>
                <span>User Profile</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>My Profile</h5>
                      <div className="card-header-right"></div>
                    </div>
                    <div className="card-block">
                      <h4 className="sub-title">User Details</h4>
                      <form>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">First Name </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              onChange={handleInputChange}
                              placeholder={`${currentUser.firstName}`}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Last Name </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              onChange={handleInputChange}
                              placeholder={`${currentUser.lastName}`}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Email</label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${currentUser.email}`}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Username</label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${currentUser.username}`}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Phone Number</label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              placeholder={`${currentUser.phoneNumber}`}
                            />
                          </div>
                        </div>

                        <div className="form-radio row">
                          <label className="col-sm-2 col-form-label">Gender</label>

                          <form>
                            <div className="radio radiofill radio-inline">
                              {currentUser.gender === "male" && (
                                <label>
                                  <input
                                    type="radio"
                                    name="male"
                                    disabled
                                    defaultChecked="checked"
                                  />
                                  <i className="helper" />
                                  Male
                                </label>
                              )}
                              {currentUser.gender !== "male" && (
                                <label>
                                  <input type="radio" disabled name="male" />
                                  <i className="helper" />
                                  Male
                                </label>
                              )}
                            </div>
                            <div className="radio radiofill radio-inline">
                              {currentUser.gender === "female" && (
                                <label>
                                  <input
                                    type="radio"
                                    name="female"
                                    disabled
                                    defaultChecked="checked"
                                  />
                                  <i className="helper" />
                                  Female
                                </label>
                              )}
                              {currentUser.gender !== "female" && (
                                <label>
                                  <input type="radio" disabled name="female" />
                                  <i className="helper" />
                                  Female
                                </label>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Role</label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={
                                currentUser.role === "super-admin"
                                  ? "Super Admin"
                                  : "Junior"
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            {isLoading ? (
                              <button
                                type="submit"
                                onClick={handleUpdateUser}
                                className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                              >
                                <i class="fa fa-circle-o-notch fa-spin"></i>Updating
                              </button>
                            ) : (
                              <button
                                type="submit"
                                onClick={handleUpdateUser}
                                className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                              >
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h5>Change Password</h5>
                      <div className="card-header-right"></div>
                    </div>
                    <div className="card-block">
                      <form>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Current Password
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              name="password"
                              onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">New Password</label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              onChange={handleInputChange}
                              className="form-control"
                              name="newPassword"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Confirm New Password
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              onChange={handleInputChange}
                              className="form-control"
                              name="confirmPassword"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          {errorMessage && (
                            <div className="row">
                              <div className="col">
                                <div className="alert alert-danger">
                                  <p> {errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="form-group">
                            {isLoading ? (
                              <button
                                type="button"
                                onClick={handleChangePassword}
                                className="btn waves-effect waves-light btn-primary btn-block"
                              >
                                <i class="fa fa-circle-o-notch fa-spin"></i>Changing
                                Password
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={handleChangePassword}
                                className="btn waves-effect waves-light btn-primary btn-block"
                              >
                                Change Password
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
