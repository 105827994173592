import React from "react";

import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ExportDATA = ({ data, fileName }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (JSONData, ReportTitle, ShowLabel = true) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    let arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    let CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      let row = "";

      //This loop will extract the label from 1st index of on array
      for (let index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }
      row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
      // eslint-disable-next-line
      let row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      // eslint-disable-next-line
      for (let index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }
      row.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    let fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    let uri = "data:text/csv;charset=utf-8," + escape(CSV);

    //this trick will generate a temp <a /> tag
    let link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const exportToXLXS = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToPDF = (csvData, fileName, print = false) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(8);

    const headers = [Object.keys(csvData[0])];
    const data = csvData.map((elt) => Object.values(elt));

    let content = {
      startY: 50,
      head: headers,
      headStyles: { fillColor: [0, 0, 0] },
      theme: "grid",
      body: data,
    };

    doc.text(fileName, marginLeft, 40);
    doc.autoTable(content);

    if (print) {
      doc.output("dataurlnewwindow");
    } else {
      doc.save(fileName + ".pdf");
    }
  };

  return (
    <>
      <div className="col-md-6">
        <button
          onClick={(e) => exportToCSV(data, fileName)}
          className="btn waves-effect waves-light btn-primary btn-file"
        >
          <i className="icofont-file-text"></i>&nbsp;CSV
        </button>
        <button
          onClick={(e) => exportToXLXS(data, fileName)}
          className="btn waves-effect waves-light btn-primary btn-file"
        >
          <i className="icofont-file-excel"></i>&nbsp; Excel
        </button>
        <button
          onClick={(e) => exportToPDF(data, fileName)}
          className="btn waves-effect waves-light btn-primary btn-file"
        >
          <i className="icofont-file-pdf"></i>&nbsp; PDF
        </button>
        <button
          onClick={(e) => exportToPDF(data, fileName, true)}
          className="btn waves-effect waves-light btn-primary btn-file"
        >
          <i className="icofont-print"></i>&nbsp;Print
        </button>
      </div>
    </>
  );
};

export default ExportDATA;
