import * as React from 'react';
import moment from "moment";
const _ = require('lodash');

const badgeCreator = (status) => {
    switch (status) {
        case "Took":
            return <span className='calender-day-success'></span>;
        case "Refused":
            return <span className='calender-day-refused'></span>;
        case "Today":
            return <span className='calender-day-current'></span>;
        default:
            return <span className='calender-day-ignored'></span>;
    }
};


export function WeeklyCalender({data,days}) {

    const calendarData = days.map(day=>(
        {
            "id": null,
            "reportDate": day.reportDate,
            "alarmTime": null,
            "status": null
        }
    ))

    let merged = [];

    for(let i=0; i<calendarData.length; i++) {
        merged.push(data.find((reportData) => moment(reportData.reportDate).format('DDMMYYYY') === moment(calendarData[i].reportDate).format('DDMMYYYY')));
    }

    const reports = merged.map((e, i) => {
        if(!e) return calendarData[i]
        return e
    });

    return (
        <>
            <table>
                <tbody>
                <tr>
                {reports.map(r=>(
                        <td className="calender-day">
                            {badgeCreator(r.status)}
                        </td>
                ))}
                </tr>
                </tbody>
            </table>
        </>
    );
};
