import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AuthService from "../services/auth.service";
import Pagination from "@material-ui/lab/Pagination";
import {NavLink} from "react-router-dom";
import ExportDATA from "../components/Util/ExportDATA";
import Swal from "sweetalert2";
import moment from "moment";
import patientService from "../services/patient.service";
import regimenService from "../services/regimen.service";

const badgeCreator = (isActive) => {
  switch (isActive) {
    case 1:
      return <span className="badge badge-success">Active</span>;
    default:
      return <span className="badge badge-light">Disabled</span>;
  }
};

const PatientsPage = () => {
  const initialInputState = {
    phoneNumber: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    address: "",
    hospitalId: "",
    regimenId: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [regimens, setRegimens] = useState([]);
  const [patientsLoaded, setPatientsLoaded] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});
  const [rawData, setRawData] = useState([]);

  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 50];

  // Handle search input
  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  // Parse Parameters
  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};
    params["hospitalId"] = AuthService.getCurrentUser().user_id;

    if (searchTitle) {
      params["phoneNumber"] = searchTitle;
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  // Load Patients
  const loadPatients = () => {
    const params = getRequestParams(searchTitle, page, pageSize);

    patientService.filterPatients(params).then((res) => {
      const {data} = res.data
      setPatients(data.users);
      setCount(data.totalPages);
      setPatientsLoaded(true);
      const newData = data.users.map((d) => ({
        id: d.id,
        firstName: d.firstName.toUpperCase(),
        lastName: d.lastName,
        fullNames: `${d.firstName.toUpperCase()} ${d.lastName}`,
        dob: moment(d.dob).format("DD MMM YYYY"),
        gender: d.gender.toUpperCase(),
        address: d.address,
        age: moment().diff(moment(d.dob), "years"),
        phoneNumber: "+" + d.phoneNumber,
        createdAt: moment(d.createdAt).format("DD MMM YYYY"),
        isActive: d.isActive,
      }));
      setRawData(newData);
    });
  };

  // Effectively loading patients
  useEffect(() => {
    regimenService.filterRegimens().then((res) => {
      setRegimens(res.data.data.regimens);
    });
    loadPatients();
  }, [page, pageSize]);

  // Search by Title
  const findByTitle = () => {
    setPage(1);
    loadPatients();
  };

  // Handle page number change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // Input handling
  const handleInputChange = (e) => {
    const isBackspace = !e.target.validity.valid && eachEntry.phoneNumber.length === 1;
    if (!isBackspace && e.target.name === "phoneNumber") {
      setEachEntry({
        ...eachEntry,
        [e.target.name]: e.target.validity.valid ? e.target.value : eachEntry.phoneNumber,
      });
    } else setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  // Create Patient
  const handleCreatePatient = (e) => {
    e.preventDefault();
    setIsLoading(true);
    patientService
      .createPatients({
        phoneNumber: "250" + eachEntry.phoneNumber,
        firstName: eachEntry.firstName,
        lastName: eachEntry.lastName,
        dob: eachEntry.dob,
        gender: eachEntry.gender,
        address: eachEntry.address,
        hospitalId: AuthService.getCurrentUser().user_id,
        regimenId: eachEntry.regimenId,
      })
      .then((result) => {
        setIsLoading(false);
        setModal(false);
        loadPatients();
        setEachEntry(initialInputState);
        if (result.data.status) {
          Swal.fire("Success !", result.data.message, "success");
        } else {
          Swal.fire("Error", result.data.message, "error");
        }
      });
  };
  // Update patient
  const handleUpdatePatient = (e) => {
    const params = {
      userId: currentPatient.id,
      firstName:
        eachEntry.firstName === "" ? currentPatient.firstName : eachEntry.firstName,
      lastName: eachEntry.lastName === "" ? currentPatient.lastName : eachEntry.lastName,
      dob: eachEntry.dob,
      gender: currentPatient.gender,
      address: eachEntry.address,
      hospitalId: AuthService.getCurrentUser().user_id,
      regimenId: eachEntry.regimenId,
    };

    e.preventDefault();
    setIsLoading(true);
    patientService.updatePatient(params).then((result) => {
      setIsLoading(false);
      setUpdateModal(!updateModal);
      if (result.data.success) {
        loadPatients();
        Swal.fire("Success !", result.data.message, "success");
      } else {
        Swal.fire("Oops, Error !", result.data.message, "error");
      }
    });
  };

  // Toggle Update Modal
  const toggleUpdate = (userId) => {
    patientService
      .filterPatients({ id: userId })
      .then((res) => {
        setCurrentPatient(res.data.data.users[0]);
        setUpdateModal(!updateModal);
      })
      .catch((err) => console.error(err));
  };

  // Disable Patient
  const changePatientStatus = (userId) => {
    patientService
      .filterPatients({ id: userId })
      .then((res) => {
        const user = res.data.data.users[0];
        setCurrentPatient(user);
        let status = user.isActive === 1 ? "deactivate" : "activate";
        Swal.fire({
          icon: "question",
          text: `Are you sure you want to ${status} ${user.firstName.toUpperCase()}`,
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.isConfirmed) {
            const params = {
              userId: currentPatient.id,
              isActive: Number(!Boolean(currentPatient.isActive)),
            };
            patientService.updatePatient(params).then((result) => {
              setIsLoading(false);
              if (result.data.success) {
                loadPatients();
                Swal.fire(
                  "Success !",
                  `${user.firstName.toUpperCase()} ${status}d`,
                  "success"
                );
              } else {
                Swal.fire("Oops, Error !", result.data.message, "error");
              }
            });
          }
        });
      })
      .catch((err) => console.error(err));
  };

  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Patients</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        <header className="page-header">
          <h1 className="page-title"> Patients</h1>
        </header>
        <div className="page-content">
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <ExportDATA
                  data={patients}
                  fileName={`Patients_${moment().format("YYYYMMDD")}`}
                />
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Phone Number Ex: 250XXXXXXXXX"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={findByTitle}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th scope="col">#</th>
                      <th scope="col">Patient name</th>
                      <th scope="col">Age</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Address</th>
                      <th scope="col">Activated / Deactivated</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rawData.map((patient, index) => (
                      <tr key={patient.id}>
                        <td>{index + 1}</td>
                        <td>
                          <NavLink to={`/patient/${patient.id}`}>
                            <strong>{patient.fullNames}</strong>
                          </NavLink>
                        </td>
                        <td>{patient.age}</td>
                        <td>{patient.gender}</td>
                        <td>
                          <div className="d-flex align-items-center nowrap text-primary">
                            <span className="icofont-ui-cell-phone p-0 mr-2" />
                            {patient.phoneNumber}
                          </div>
                        </td>
                        <td>{patient.address}</td>
                        <td>{badgeCreator(patient.isActive)}</td>
                        <td>
                          <div className="actions">
                            <button
                              className="btn btn-info btn-sm btn-square rounded-pill"
                              onClick={() => toggleUpdate(patient.id)}
                            >
                              <span className="btn-icon icofont-ui-edit" />
                            </button>
                            {patient.isActive === 1 ? (
                              <button
                                className="btn btn-error btn-sm btn-square rounded-pill"
                                onClick={() => changePatientStatus(patient.id)}
                              >
                                <span className="btn-icon icofont-not-allowed" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-success btn-sm btn-square rounded-pill"
                                onClick={() => changePatientStatus(patient.id)}
                              >
                                <span className="btn-icon icofont-tick-mark" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className=" mt-4">
                {"Patients per Page: "}

                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  color="primary"
                  variant="outlined"
                  shape="round"
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>
          <div className="add-action-box">
            <button
              className="btn btn-primary btn-lg btn-square rounded-pill"
              onClick={() => setModal(!modal)}
            >
              <span className="btn-icon icofont-plus" />
            </button>
          </div>
        </div>
      </div>

      {/* Add patient modals */}
      <Modal isOpen={modal} toggle={() => setModal(!modal)} fade>
        <ModalHeader toggle={() => setModal(!modal)}>Add new patient</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={eachEntry.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="lastName"
                value={eachEntry.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="date"
                required
                name="dob"
                value={eachEntry.dob}
                onChange={handleInputChange}
                placeholder="Date of birth"
              />
            </div>
            <div className="form-group">
              <select
                name="gender"
                className="form-control form-control-default"
                onChange={handleInputChange}
              >
                <option value={null}>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="address"
                value={eachEntry.address}
                onChange={handleInputChange}
                placeholder="Address"
              />
            </div>
            <div className="form-group">
              <select
                name="regimenId"
                className="form-control form-control-default"
                onChange={handleInputChange}
              >
                <option value={null}>Select Regimen</option>
                {regimens.map((regimen) => (
                  <option value={regimen.id}>{regimen.name}</option>
                ))}
              </select>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">+250</span>
              </div>
              <input
                className="form-control"
                type="text"
                pattern="[0-9]*"
                maxLength="9"
                required
                value={eachEntry.phoneNumber}
                name="phoneNumber"
                onChange={handleInputChange}
                placeholder="Phone"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            <button
              type="button"
              className="btn btn-error"
              onClick={() => setModal(!modal)}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                type="submit"
                onClick={handleCreatePatient}
                className="btn btn-info"
              >
                <i className="fa fa-circle-o-notch fa-spin"/>&nbsp;Creating patient
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleCreatePatient}
                className="btn btn-info"
              >
                Add patient
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add hospital modals */}
      {/* Update hospital modals */}
      <Modal isOpen={updateModal} toggle={() => toggleUpdate(null)} fade>
        <ModalHeader toggle={() => toggleUpdate(null)}>Update patient</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={
                  eachEntry.firstName === ""
                    ? currentPatient.firstName
                    : eachEntry.firstName
                }
                onChange={handleInputChange}
                placeholder={currentPatient.firstName}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="lastName"
                value={
                  eachEntry.lastName === "" ? currentPatient.lastName : eachEntry.lastName
                }
                onChange={handleInputChange}
                placeholder={currentPatient.lastName}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="date"
                required
                name="dob"
                value={eachEntry.dob === "" ? currentPatient.dob : eachEntry.dob}
                onChange={handleInputChange}
                placeholder={currentPatient.dob}
              />
            </div>
            <div className="form-group">
              <select
                name="gender"
                className="form-control form-control-default"
                onChange={handleInputChange}
                disabled
                value={currentPatient.gender}
              >
                <option value={null}>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                required
                name="address"
                value={
                  eachEntry.address === "" ? currentPatient.address : eachEntry.address
                }
                onChange={handleInputChange}
                placeholder="Address"
              />
            </div>
            <div className="form-group">
              <select
                name="regimenId"
                className="form-control form-control-default"
                onChange={handleInputChange}
                value={currentPatient.regimenId}
              >
                <option value={null}>Select Regimen</option>
                {regimens.map((regimen) => (
                  <option value={regimen.id}>{regimen.name}</option>
                ))}
              </select>
            </div>

            <div className="input-group mb-3">
              <input
                className="form-control"
                type="text"
                pattern="[0-9]*"
                maxLength="12"
                required
                value={eachEntry.phoneNumber}
                name="phoneNumber"
                onChange={handleInputChange}
                placeholder={currentPatient.phoneNumber}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            {isLoading ? (
              <button
                type="button"
                onClick={handleUpdatePatient}
                className="btn btn-info"
              >
                <i className="fa fa-circle-o-notch fa-spin"/>&nbsp;Updating patient
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleUpdatePatient}
                className="btn btn-info"
              >
                Update patient
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add hospital modals */}
    </main>
  );
};

export default PatientsPage;
