import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import ExportDATA from "../components/Util/ExportDATA";
import Swal from "sweetalert2";
import moment from "moment";
import regimenService from "../services/regimen.service";
import AuthService from "../services/auth.service";


const RegimensPage = () => {
  const initialInputState = {
    name: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [regimens, setRegimens] = useState([]);
  const [regimensLoaded, setRegimensLoaded] = useState(false);
  const [currentRegimen, setCurrentRegimen] = useState({});
  const [rawData, setRawData] = useState([]);

  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 50];

  // Handle search input
  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  // Parse Parameters
  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["name"] = searchTitle;
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  // Load regimens
  const loadRegimens = () => {
    const params = getRequestParams(searchTitle, page, pageSize);

    regimenService.filterRegimens(params).then((res) => {
      setRegimens(res.data.data.regimens);
      setCount(res.data.data.totalPages);
      setRegimensLoaded(true);
      setRawData(res.data.data.regimens);
    });
  };

  // Effectively loading regimens
  useEffect(() => {
    loadRegimens();
  }, [page, pageSize]);

  // Search by Title
  const findByTitle = () => {
    setPage(1);
    loadRegimens();
  };

  // Handle page number change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // Input handling
  const handleInputChange = (e) => {
    const isBackspace = !e.target.validity.valid && eachEntry.name.length === 1;
    if (!isBackspace && e.target.name === "name") {
      setEachEntry({
        ...eachEntry,
        [e.target.name]: e.target.validity.valid ? e.target.value : eachEntry.name,
      });
    } else setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  // Create Regimen
  const handleCreateRegimen = (e) => {
    e.preventDefault();
    setIsLoading(true);
    regimenService
      .createRegimens({
        name: eachEntry.name,
      })
      .then((result) => {
        setIsLoading(false);
        setModal(false);
        loadRegimens();
        if (result.data.success) {
          Swal.fire("Success !", result.data.message, "success");
        } else {
          Swal.fire("Error", result.data.message, "error");
        }
      });
  };
  // Update Regimen
  const handleUpdateRegimen = (e) => {
    const params = {
      regimenId: currentRegimen.id,
      name: eachEntry.name,
    };

    e.preventDefault();
    setIsLoading(true);
    regimenService.updateRegimen(params).then((result) => {
      setIsLoading(false);
      setUpdateModal(!updateModal);
      if (result.data.success) {
        loadRegimens();
        Swal.fire("Success !", result.data.message, "success");
      } else {
        Swal.fire("Oops, Error !", result.data.message, "error");
      }
    });
  };

  // Toggle Update Modal
  const toggleUpdate = (regimenId) => {
    regimenService
      .filterRegimens({ id: regimenId })
      .then((res) => {
        setCurrentRegimen(res.data.data.regimens[0]);
        setUpdateModal(!updateModal);
      })
      .catch((err) => console.error(err));
  };

  // // Delete Regimen
  // const deleteRegimen = (regimenId) => {
  //   regimenService
  //     .filterRegimens({ id: regimenId })
  //     .then((res) => {
  //       const regimen = res.data.data.regimens[0];
  //       setCurrentRegimen(regimen);
  //       Swal.fire({
  //         icon: "question",
  //         text: `Are you sure you want to delete regimen ${currentRegimen.name}`,
  //         showCancelButton: true,
  //         confirmButtonText: `Yes`,
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           const params = {
  //             regimenId: currentRegimen.id,
  //           };
  //           regimenService.deleteRegimen(params).then((result) => {
  //             setIsLoading(false);
  //             if (result.data.success) {
  //               loadRegimens();
  //               Swal.fire("Success !", `${regimen.name} has been deleted`, "success");
  //             } else {
  //               Swal.fire("Oops, Error !", result.data.message, "error");
  //             }
  //           });
  //         }
  //       });
  //     })
  //     .catch((err) => console.error(err));
  // };

  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Regimens</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        <header className="page-header">
          <h1 className="page-title">Regimens</h1>
        </header>
        <div className="page-content">
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <ExportDATA
                  data={regimens}
                  fileName={`Regimens_${moment().format("YYYYMMDD")}`}
                />
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by title"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={findByTitle}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th scope="col">ID</th>
                      <th scope="col">Regimen Title</th>
                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {rawData.map((regimen) => (
                      <tr key={regimen.id}>
                        <td>{regimen.id}</td>
                        <td>
                          <strong>{regimen.name}</strong>
                        </td>

                        {/* <td>
                          <div className="actions">
                            <button
                              className="btn btn-info btn-sm btn-square rounded-pill"
                              onClick={() => toggleUpdate(regimen.id)}
                            >
                              <span className="btn-icon icofont-ui-edit" />
                            </button>

                            <button
                              className="btn btn-error btn-sm btn-square rounded-pill"
                              onClick={() => deleteRegimen(regimen.id)}
                            >
                              <span className="btn-icon icofont-not-allowed" />
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className=" mt-4">
                {"Regimens per Page: "}

                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  color="primary"
                  variant="outlined"
                  shape="round"
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>
          {AuthService.getCurrentUser().role === "administrator" && (
              <div className="add-action-box">
                <button
                    className="btn btn-primary btn-lg btn-square rounded-pill"
                    onClick={() => setModal(!modal)}
                >
                  <span className="btn-icon icofont-plus" />
                </button>
              </div>
          )}
        </div>
      </div>

      {/* Add regimen modals */}
      <Modal isOpen={modal} toggle={() => setModal(!modal)} fade>
        <ModalHeader toggle={() => setModal(!modal)}>Add new regimen</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                value={eachEntry.name}
                onChange={handleInputChange}
                placeholder="Regimen Name"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            <button
              type="button"
              className="btn btn-error"
              onClick={() => setModal(!modal)}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                type="submit"
                onClick={handleCreateRegimen}
                className="btn btn-info"
              >
                <i className="fa fa-circle-o-notch fa-spin"></i>&nbsp;Creating Regimen
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleCreateRegimen}
                className="btn btn-info"
              >
                Add Regimen
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add regimen modals */}
      {/* Update Regimen modals */}
      <Modal isOpen={updateModal} toggle={() => toggleUpdate(null)} fade>
        <ModalHeader toggle={() => toggleUpdate(null)}>Update regimen</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                value={eachEntry.name}
                onChange={handleInputChange}
                placeholder={currentRegimen.name}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer d-block">
          <div className="actions justify-content-between">
            {isLoading ? (
              <button
                type="button"
                onClick={handleUpdateRegimen}
                className="btn btn-info"
              >
                <i className="fa fa-circle-o-notch fa-spin"></i>&nbsp;Updating Regimen
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleUpdateRegimen}
                className="btn btn-info"
              >
                Update Regimen
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* end Add regimen modals */}
    </main>
  );
};

export default RegimensPage;
