import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AuthService from "../services/auth.service";
import Pagination from "@material-ui/lab/Pagination";
import {NavLink, useHistory} from "react-router-dom";
import ExportDATA from "../components/Util/ExportDATA";
import Swal from "sweetalert2";
import moment from "moment";
import reportService from "../services/report.service";
import {WeeklyCalender} from "../components/WeeklyCalender";

const badgeCreator = (isActive) => {
  switch (isActive) {
    case 1:
      return <span className="badge badge-success">Active</span>;
    default:
      return <span className="badge badge-light">Disabled</span>;
  }
};

const ReportsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [rawData, setRawData] = useState([]);
  const [weekDates,setWeekDates] = useState([])
  const [weekCalendar,setWeekCalendar] = useState([])

  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 50];

  // Handle search input
  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  // Parse Parameters
  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};
    params["hospitalId"] = AuthService.getCurrentUser().user_id;
    params["role"] = AuthService.getCurrentUser().role

    if (searchTitle) {
      params["phoneNumber"] = searchTitle;
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  // Load Reports
  const loadReports = () => {
    const params = getRequestParams(searchTitle, page, pageSize);

    reportService.filterReports(params).then((res) => {
      const {data} = res.data
      setReports(data.reports);
      setCount(data.totalPages);
      setReportsLoaded(data.success);
      const newData = data.reports.map((d) => ({
        userId: d.id,
        fullNames: `${d.firstName.toUpperCase()} ${d.lastName}`,
        gender: d.gender.toUpperCase(),
        age: moment().diff(moment(d.dob), "years"),
        phoneNumber: "+" + d.phoneNumber,
        userReports: d.reports,
      }));
      setRawData(newData);
    });
  };
  // Effectively loading hospitals
  useEffect(() => {
    loadReports();
    getWeekDates();
  }, [page, pageSize]);
  // Search by Title
  const findByTitle = () => {
    setPage(1);
    loadReports();
  };
  // Handle page number change
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  // Handle page size change
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };



  const getWeekDates = () => {

    const lastDay = moment().subtract(2,'weeks');
    let today = moment()

    let days = [];
    let weekCalendar = []

    while (today >= lastDay) {
      days.push(today.format('Do MMM'));
      weekCalendar.push({
        "id": "01a3abd4-88b0-4d10-ab33-3b82bcf02802",
        "reportDate": today.toISOString(),
        "alarmTime": "19:30:00",
        "status": "null"
      })
      today = today.clone().subtract(1, 'd');
    }
    setWeekDates(days)
    setWeekCalendar(weekCalendar)
}
  return (
    <main className="main-content">
      <Helmet>
        <title>Companion | Reports</title>
      </Helmet>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      <div className="main-content-wrap">
        <header className="page-header">
          <h1 className="page-title">Reports</h1>
        </header>
        <div className="page-content">
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <ExportDATA
                  data={reports}
                  fileName={`Patients_${moment().format("YYYYMMDD")}`}
                />
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Phone Number Ex: 250XXXXXXXXX"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={findByTitle}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card mb-1">
                  <div className="flex-content">
                    <h4>Labels: </h4>
                    <div className="flex-content">
                      <div className="calender-day-current col"> Today </div>
                      <div className="calender-day-success col"> Took </div>
                      <div className="calender-day-refused col"> Refused </div>
                      <div className="calender-day-ignored col"> Pending </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th scope="col">#</th>
                      <th scope="col">Patient name</th>
                      <th scope="col">Age</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Phone</th>
                      <th scope="col">
                        <thead>
                        <tr>
                          {weekDates.map(day=>(
                              <th key={day} className='calender-day'>
                                {day}
                              </th>
                          ))}
                        </tr>
                        </thead>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rawData.map((reports, index) => (
                      <tr key={reports.id}>
                        <td>{index + 1}</td>
                        <td>
                          <NavLink to={`/patient/${reports.userId}`}>
                            <strong>{reports.fullNames}</strong>
                          </NavLink>
                        </td>
                        <td>{reports.age}</td>
                        <td>{reports.gender}</td>
                        <td>
                          <div className="d-flex align-items-center nowrap text-primary">
                            <span className="icofont-ui-cell-phone p-0 mr-2" />
                            {reports.phoneNumber}
                          </div>
                        </td>
                        <td><WeeklyCalender days={weekCalendar} data={reports.userReports}/></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className=" mt-4">
                {"Reports per Page: "}

                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>

                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  color="primary"
                  variant="outlined"
                  shape="round"
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </main>
  );
};

export default ReportsPage;
