import axios from "axios";
const API_URL = require("../api").companionAPI;

class HospitalService {
  filterHospitals(params) {
    let queryUrl = API_URL + "/hospital/get";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");
    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
      },
      params,
    };
    return axios(config);
  }

  createHospitals(params) {
    let queryUrl = API_URL + "/hospital/create";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");

    const config = {
      method: "post",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    };
    return axios(config);
  }
  updateHospital(params) {
    let queryUrl = API_URL + "/hospital/update?hospitalId=" + params.hospitalId;
    let token = localStorage.getItem("token").replace(/['"]+/g, "");

    const config = {
      method: "put",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    };
    return axios(config);
  }
}

export default new HospitalService();
