import React from "react";
import { NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";

const SideBar = () => {
  return (
    <div id="navbar2" className="app-navbar vertical">
      <div className="navbar-wrap">
        <button className="no-style navbar-toggle navbar-close icofont-close-line d-lg-none" />
        <div className="app-logo">
          <div className="logo-wrap">
            <img
              src="../assets/img/logo.svg"
              alt=""
              className="logo-img"
            />
          </div>
        </div>
        <div className="main-menu">
          <nav className="main-menu-wrap">
            <ul className="menu-ul">
              <li className="menu-item">
                <span className="group-title">
                  {AuthService.getCurrentUser().role} ACCOUNT
                </span>
              </li>
              <li className="menu-item">
                <NavLink to="/" className="item-link">
                  <span className="link-icon icofont-dashboard" />
                  <span className="link-text">Statistics</span>
                </NavLink>
              </li>
              {AuthService.getCurrentUser().role === "administrator" && (
                <li className="menu-item">
                  <NavLink to="/hospitals" className="item-link">
                    <span className="link-icon icofont-hospital" />
                    <span className="link-text">Hospitals</span>
                  </NavLink>
                </li>
              )}
              <li className="menu-item">
                <NavLink to="/regimens" className="item-link">
                  <span className="link-icon icofont-capsule" />
                  <span className="link-text">Regimen</span>
                </NavLink>
              </li>
              {/* <li className="menu-item">
                <NavLink to="/records" className="item-link">
                  <span className="link-icon icofont-stethoscope-alt" />
                  <span className="link-text">Records</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/reports" className="item-link">
                  <span className="link-icon icofont-patient-file" />
                  <span className="link-text">Reports</span>
                </NavLink>
              </li> */}
              {AuthService.getCurrentUser().role === "hospital" && (
                <><li className="menu-item">
                  <NavLink to="/patients" className="item-link">
                    <span className="link-icon icofont-paralysis-disability" />
                    <span className="link-text">Patients</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                <NavLink to="/reports" className="item-link">
                <span className="link-icon icofont-file-document" />
                <span className="link-text">Reports</span>
                </NavLink>
                </li>
                  <li className="menu-item">
                    <NavLink to="/records" className="item-link">
                      <span className="link-icon icofont-test-tube" />
                      <span className="link-text">Records</span>
                    </NavLink>
                  </li>
                </>
              )}
              <li className="menu-item">
                <NavLink to="/chat" className="item-link">
                  <span className="link-icon icofont-ui-chat" />
                  <span className="link-text">Chat Room</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="add-patient">
          {/* <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#add-patient"
          >
            <span className="btn-icon icofont-plus mr-2" />
            Add Patient
          </button> */}
        </div>
        <div className="assistant-menu">
          {/*<NavLink to="/settings" className="link">*/}
          {/*  <span className="link-icon icofont-ui-settings" />*/}
          {/*  Settings*/}
          {/*</NavLink>*/}
          {/*<NavLink to="/support" className="link">*/}
          {/*  <span className="link-icon icofont-question-square" />*/}
          {/*  FAQ &amp; Support*/}
          {/*</NavLink>*/}
        </div>
        <div className="navbar-skeleton vertical">
          <div className="top-part">
            <div className="sk-logo bg animated-bg" />
            <div className="sk-menu">
              <span className="sk-menu-item menu-header bg-1 animated-bg" />
              <span className="sk-menu-item bg animated-bg w-75" />
              <span className="sk-menu-item bg animated-bg w-80" />
              <span className="sk-menu-item bg animated-bg w-50" />
              <span className="sk-menu-item bg animated-bg w-75" />
              <span className="sk-menu-item bg animated-bg w-50" />
              <span className="sk-menu-item bg animated-bg w-60" />
            </div>
            <div className="sk-menu">
              <span className="sk-menu-item menu-header bg-1 animated-bg" />
              <span className="sk-menu-item bg animated-bg w-60" />
              <span className="sk-menu-item bg animated-bg w-40" />
              <span className="sk-menu-item bg animated-bg w-60" />
              <span className="sk-menu-item bg animated-bg w-40" />
              <span className="sk-menu-item bg animated-bg w-40" />
              <span className="sk-menu-item bg animated-bg w-40" />
              <span className="sk-menu-item bg animated-bg w-40" />
            </div>
            <div className="sk-menu">
              <span className="sk-menu-item menu-header bg-1 animated-bg" />
              <span className="sk-menu-item bg animated-bg w-60" />
              <span className="sk-menu-item bg animated-bg w-50" />
            </div>
            <div className="sk-button animated-bg w-90" />
          </div>
          {/*<div className="bottom-part">*/}
          {/*  <div className="sk-menu">*/}
          {/*    <span className="sk-menu-item bg-1 animated-bg w-60" />*/}
          {/*    <span className="sk-menu-item bg-1 animated-bg w-80" />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="horizontal-menu">
            <span className="sk-menu-item bg animated-bg" />
            <span className="sk-menu-item bg animated-bg" />
            <span className="sk-menu-item bg animated-bg" />
            <span className="sk-menu-item bg animated-bg" />
            <span className="sk-menu-item bg animated-bg" />
            <span className="sk-menu-item bg animated-bg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
