import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import AuthService from "../services/auth.service";
import Pagination from "@material-ui/lab/Pagination";
import {NavLink, useParams} from "react-router-dom";
import ExportDATA from "../components/Util/ExportDATA";
import Swal from "sweetalert2";
import moment from "moment";
import patientService from "../services/patient.service";
import regimenService from "../services/regimen.service";
import recordService from "../services/record.service";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const badgeCreator = (isActive) => {
    switch (isActive) {
        case 1:
            return <span className="badge badge-success">Active</span>;
        default:
            return <span className="badge badge-light">Disabled</span>;
    }
};

const PatientPage = () => {
    const initialInputState = {
        phoneNumber: "",
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        address: "",
        hospitalId: "",
        regimenId: "",
    };

    const { userId } = useParams();

    const [eachEntry, setEachEntry] = useState(initialInputState);
    const [isLoading, setIsLoading] = useState(false);
    const [patients, setPatients] = useState([]);
    const [regimens, setRegimens] = useState([]);
    const [records, setRecords] = useState([]);
    const [recordsLoaded, setRecordsLoaded] = useState(false);
    const [patientLoaded, setPatientLoaded] = useState(false);
    const [currentPatient, setCurrentPatient] = useState({});
    const [rawData, setRawData] = useState([]);
    const [updateModal, setUpdateModal] = useState(false);

    // Parse Parameters
    const getRequestParams = ( userId) => {
        let params = {};
        params["hospitalId"] = AuthService.getCurrentUser().user_id;

        params["id"] = userId

        return params;
    };

    const options = {
        type: 'line',
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
            },
        },
    };

    const labels =  ['January', 'February', 'March', 'April', 'May', 'June', 'July']

    const data = {
        labels,
        datasets: [
            {
                label: 'C4 Count',
                data: [],
                borderColor: '#64B5F6',
                borderWidth: 2
            },
            {
                label: 'VL Number',
                data: [],
                borderColor: '#b7ce63',
                borderWidth: 2
            },
        ],
    };

    // Load Patients
    const loadPatients = () => {
        const params = getRequestParams( userId);

        patientService.filterPatients(params).then((res) => {
            const {data} = res.data
            setPatients(data.users);
            setPatientLoaded(true);
            const newData = data.users.map((d) => ({
                id: d.id,
                firstName: d.firstName.toUpperCase(),
                lastName: d.lastName,
                dob: moment(d.dob).format("DD MMM YYYY"),
                gender: d.gender.toUpperCase(),
                address: d.address,
                age: moment().diff(moment(d.dob), "years"),
                phoneNumber: "+" + d.phoneNumber,
                createdAt: moment(d.createdAt).format("DD MMM YYYY"),
                isActive: d.isActive,
            }));
            setCurrentPatient(data.users[0]);
            setRawData(newData);
        });

        recordService.filterRecords({userId:userId}).then((res) => {
            const {data} = res.data
            setRecordsLoaded(true);
            const newData = data.records.map((d) => ({
                id:d.id,
                userId: d.user.id,
                fullNames: `${d.user.firstName.toUpperCase()} ${d.user.lastName}`,
                gender: d.user.gender.toUpperCase(),
                age: moment().diff(moment(d.user.dob), "years"),
                phoneNumber: "+" + d.user.phoneNumber,
                reportedAt: moment(d.createdAt).format("DD MMM YYYY"),
                CD4count:d.CD4count ? `${d.CD4count} avg` : '-',
                bloodPressure: `${d.bloodPressure} mmHg`,
                recordTypeId:d.recordTypeId,
                vlNumber:d.vlNumber?`${d.vlNumber} avg`:'-',
                weight:`${d.weight} Kg`
            }));
            setRecords(newData);
        });
    };

    // Effectively loading patients
    useEffect(() => {
        regimenService.filterRegimens().then((res) => {
            const {data} = res.data
            setRegimens(data.regimens);
        });
        loadPatients();
    }, []);



    // Input handling
    const handleInputChange = (e) => {
        const isBackspace = !e.target.validity.valid && eachEntry.phoneNumber.length === 1;
        if (!isBackspace && e.target.name === "phoneNumber") {
            setEachEntry({
                ...eachEntry,
                [e.target.name]: e.target.validity.valid ? e.target.value : eachEntry.phoneNumber,
            });
        } else setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    };
    // Update patient
    const handleUpdatePatient = (e) => {
        const params = {
            userId,
            firstName:
                eachEntry.firstName === "" ? currentPatient.firstName : eachEntry.firstName,
            lastName: eachEntry.lastName === "" ? currentPatient.lastName : eachEntry.lastName,
            dob: eachEntry.dob,
            gender: currentPatient.gender,
            address: eachEntry.address,
            hospitalId: AuthService.getCurrentUser().user_id,
            regimenId: eachEntry.regimenId,
        };

        e.preventDefault();
        setIsLoading(true);
        patientService.updatePatient(params).then((result) => {
            setIsLoading(false);
            setUpdateModal(!updateModal);
            if (result.data.success) {
                loadPatients();
                Swal.fire("Success !", result.data.message, "success");
            } else {
                Swal.fire("Oops, Error !", result.data.message, "error");
            }
        });
    };
    // Disable Patient
    const changePatientStatus = (userId) => {
        patientService
            .filterPatients({ id: userId })
            .then((res) => {
                const user = res.data.data.users[0];
                setCurrentPatient(user);
                let status = user.isActive === 1 ? "deactivate" : "activate";
                Swal.fire({
                    icon: "question",
                    text: `Are you sure you want to ${status} ${user.firstName.toUpperCase()}`,
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const params = {
                            userId: currentPatient.id,
                            isActive: Number(!Boolean(currentPatient.isActive)),
                        };
                        patientService.updatePatient(params).then((result) => {
                            setIsLoading(false);
                            if (result.data.success) {
                                loadPatients();
                                Swal.fire(
                                    "Success !",
                                    `${user.firstName.toUpperCase()} ${status}d`,
                                    "success"
                                );
                            } else {
                                Swal.fire("Oops, Error !", result.data.message, "error");
                            }
                        });
                    }
                });
            })
            .catch((err) => console.error(err));
    };

    return (
        <main className="main-content">
            <Helmet>
                <title>Companion | {userId?"Patient":"Error"} </title>
            </Helmet>
            <div className="app-loader">
                <i className="icofont-spinner-alt-4 rotate" />
            </div>
            <div className="main-content-wrap">
                {patientLoaded &&(
                    <div>
                    <header className="page-header">
                        <h2 className="page-title"> {`Patient: ${currentPatient.firstName} ${currentPatient.lastName}`}</h2>
                    </header>
                    <div className="page-content">
                    <div className="card mb-0">
                    <div className="card-body">
                    <div className="row">
                    <div className="col-md-12">
                      <h4>Patient Info</h4>
                        <div className="row">
                            <div className="col-6 col-md-6">
                                <form>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="firstName"
                                            value={
                                                eachEntry.firstName === ""
                                                    ? currentPatient.firstName
                                                    : eachEntry.firstName
                                            }
                                            onChange={handleInputChange}
                                            placeholder={currentPatient.firstName}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            name="lastName"
                                            value={
                                                eachEntry.lastName === "" ? currentPatient.lastName : eachEntry.lastName
                                            }
                                            onChange={handleInputChange}
                                            placeholder={currentPatient.lastName}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="date"
                                            required
                                            name="dob"
                                            value={eachEntry.dob === "" ? currentPatient.dob : eachEntry.dob}
                                            onChange={handleInputChange}
                                            placeholder={currentPatient.dob}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <select
                                            name="gender"
                                            className="form-control form-control-default"
                                            onChange={handleInputChange}
                                            disabled
                                            value={currentPatient.gender}
                                        >
                                            <option value={null}>Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="col-6 col-md-6">
                                <form>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            name="address"
                                            value={
                                                eachEntry.address === "" ? currentPatient.address : eachEntry.address
                                            }
                                            onChange={handleInputChange}
                                            placeholder="Address"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <select
                                            name="regimenId"
                                            className="form-control form-control-default"
                                            onChange={handleInputChange}
                                            value={currentPatient.regimenId}
                                        >
                                            <option value={null}>Select Regimen</option>
                                            {regimens.map((regimen) => (
                                                <option value={regimen.id}>{regimen.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input
                                            className="form-control"
                                            type="text"
                                            pattern="[0-9]*"
                                            maxLength="12"
                                            required
                                            value={eachEntry.phoneNumber}
                                            name="phoneNumber"
                                            onChange={handleInputChange}
                                            placeholder={currentPatient.phoneNumber}
                                        />
                                    </div>
                                    <div className="actions justify-content-between">
                                        {isLoading ? (
                                            <button
                                                type="button"
                                                onClick={handleUpdatePatient}
                                                className="btn btn-primary btn-block"
                                            >
                                                <span className="btn-loader icofont-spinner"></span>>&nbsp;Updating patient
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                onClick={handleUpdatePatient}
                                                className="btn btn-primary btn-block"
                                            >
                                                Update patient
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                        <div className="col-12 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                   Patient Records
                                </div>
                                <div className="card-body">
                                    <Line options={options} data={data} />
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr className="bg-primary text-white">
                                                <th scope="col">Names</th>
                                                <th scope="col">Age</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Blood Pressure</th>
                                                <th scope="col">Weight</th>
                                                <th scope="col">CD4 Count</th>
                                                <th scope="col">VL Number</th>
                                                <th scope="col">Recorded At</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {records.map((record, index) => (
                                                <tr key={record.id}>
                                                    <td>
                                                        <NavLink to={`/patient/${record.userId}`}>
                                                            <strong>{record.fullNames}</strong>
                                                        </NavLink>
                                                    </td>
                                                    <td>{record.age}</td>
                                                    <td>
                                                        {record.gender}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center nowrap text-primary">
                                                            <span className="icofont-ui-cell-phone p-0 mr-2" />
                                                            {record.phoneNumber}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {record.bloodPressure}
                                                    </td>
                                                    <td>
                                                        {record.weight}
                                                    </td>
                                                    <td>
                                                        {record.CD4count}
                                                    </td>
                                                    <td>
                                                        {record.vlNumber}
                                                    </td>
                                                    <td>
                                                        {record.reportedAt}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                )}

            </div>
        </main>
    );
};

export default PatientPage;
