import axios from "axios";
const API_URL = require("../api").companionAPI;

class PatientService {
  filterPatients(params) {
    let queryUrl = API_URL + "/users/get";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");
    const config = {
      method: "get",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
      },
      params,
    };
    return axios(config);
  }

  createPatients(params) {
    let queryUrl = API_URL + "/user/auth/register";
    let token = localStorage.getItem("token").replace(/['"]+/g, "");

    const config = {
      method: "post",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    };
    return axios(config);
  }
  updatePatient(params) {
    let queryUrl = API_URL + "/users/update?userId=" + params.userId;
    let token = localStorage.getItem("token").replace(/['"]+/g, "");

    const config = {
      method: "put",
      url: queryUrl,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    };
    return axios(config);
  }
}

export default new PatientService();
